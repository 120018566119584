@import './../../../default.scss';

.AlertWrapper {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 0;
	left: 0;
	height: 0px;
}

.Alert {
	height: 54px;
	width: 200px;
	margin: 0px auto;
	margin-top: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: margin-top ease-in-out 100ms;
	position: absolute;
	z-index: 1000;

	background-color: $black;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding-top: 10px;
	border: 1px solid rgba(255,255, 255, 0.15);
	box-shadow: 0px 0px 10px $dropShadow;
}

.Children {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -10px;
}

.Show {
	margin-top: 0px;
}

.ShowWrapper {
	height: 50px;
}