@import "./../../default.scss";

.BackgroundWrapper {
	width: 100%;
	height: 200px;
	box-shadow: 0px 0px 40px $trueBlack;
	position: relative;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Background {
	width: 100%;
	height: 100%;
	color: whitesmoke;
	clip-path: polygon(0 40%, 100% 20%, 100% 100%, 0% 100%);
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
}
// .BackgroundWrapper {
// 	content: "";
// 	position: absolute;
// 	left: 0;
// 	bottom: -20px;
// 	box-shadow: 0px 0px 10px $dropShadow;
// 	height: 20px;
// 	width: 100%;
// 	min-width: 100px;
// 	background-image: linear-gradient($black, transparent);
// 	z-index: 100;
// 	border: 2px solid red;
// }
.Logo {
	height: auto;
	width: 600px;
	position: absolute;
	bottom: 0;
	z-index: 100;
}

.Socials {
	position: absolute;
	top: 15px;
	left: 10px;
	display: flex;
	align-items: center;
	font-size: 40px;
	transition: all ease-in-out 200ms;
}

.Socials > * {
	margin: 0px 8px;
}

@media only screen and (min-width: 500px) and (max-width: 750px) {
	.BackgroundWrapper {
		height: 200px;
	}
	.Logo {
		width: 450px;
	}
}
@media only screen and (max-width: 500px) {
	.BackgroundWrapper {
		height: 150px;
	}
	.Logo {
		width: 400px;
		max-width: calc(100% - 40px);
	}
	.Socials {
		font-size: 30px;
	}
}
