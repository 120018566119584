@import './../../default.scss';

.Input {
	width: 100%; 
	height: 40px;
	max-height: 100%;
	border: 2px solid $grey;
	border-radius: 5px;
	background-color: whitesmoke;
	color: $grey;
	padding: 5px 10px;
	outline: none;
}

.Input:active {
	outline: none;
	border: 2px solid $grey;
	border-radius: 5px;
}

.Input::placeholder, .Input::-ms-input-placeholder {
	color: $black;
	padding-left: 10px;
}

.Error {
	color: $errorColor;
}

