@import "./../../default.scss";

.Link {
	color: $white;
	cursor: pointer;
	text-decoration: none;
	transition: all ease-in-out 100ms;
}

.Container:hover, .Container a:focus {
	transform: scale(1.2);
}

.Container:focus {
	border: 2px solid $black;
	border-radius: 50%;
}