@import './../../default.scss';

.Button {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-weight: bold;
	text-shadow: 0px 2px 4px $black;
	cursor: pointer;
	border-radius: 5px;
	outline: none;
	border: 2px solid $dropShadow;
	box-shadow: 2px 2px 10px $dropShadow;
	transition-property: box-shadow;
	transition-timing-function: ease-in-out;
	transition-duration: 150ms;
	font-size: $headerFont;
	height: 100%;
	width: 100%;
}

.Button:disabled {
	background-color: $lightGrey !important;
	cursor: default;
}

.Button:hover {
	box-shadow: 2px 2px 10px $black;
}

.Standard {

}
