@import './../App.scss';

.SuggestionBox {
	width: 400px;
	max-width: calc(100% - 20px);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px;
	background-color: $grey;
	border-radius: 5px;
	box-shadow: 0px 4px 10px $dropShadow;
	border: 2px solid $black;
}

.About{
	padding: 20px;
	padding-bottom: 40px;
	text-align: center;
	font-size: $normalFont;
	line-height: $normalLineheight;
	width: 600px;
	max-width: calc(100% - 20px);
	margin: 0 auto;
	background-color: $black;
	border-radius: 5px;
	box-shadow: 0px 4px 20px $dropShadow;
	display: flex; 
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.RedBG {
	background-color: $bannerRed;
}
.OrangeBG {
	background-color: $bannerOrange;
}
.PurpleBG {
	background-color: $bannerPurple;
}
.FushiaBG {
	background-color: $bannerFushia;
}
.BlueBG {
	background-color: $bannerBlue;
}
.GreenBG {
	background-color: $bannerGreen;
}
.YellowBG {
	background-color: $bannerYellow;
}
.TealBG {
	background-color: $bannerTeal;
}

.InputSection {
	display: grid;
	grid-template-columns: calc(100% - 75px) 75px;
	height: 40px;
	width: 100%;
}

.Support {
	display: grid;
	grid-template-columns: 50% 50%;
	width: inherit;
}

.Patreon {
	margin: 0 auto;
	width: 100%;
	height: 100%;
	background-color: $grey;
	box-shadow: 0px 0px 10px $dropShadow;
	border-radius: 5px;
	border: 2px solid $black;
	display: grid;
	grid-template-rows: 60px calc(100% - 60px);
	overflow: hidden;
	cursor: pointer;
}

.Patreon > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.Patreon > section {
	width: 100%;
	height: 100%;
	box-shadow: 0px 2px 10px $dropShadow;
	display: flex;
	align-items: center;
	justify-content: center;
}

.PatreonImg {
	width: 80%;
	height: auto;
	padding: 5px 0px 15px 0px; 
}

.SupportInfo {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.SupportInfo > p {
	padding: 0px 20px;
}

.DogeContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: calc(100% - 40px);
	height: 40px; 
}

.DogeText {
	color: $bannerOrange;
	font-size: 14px;
	width: 100%;
	max-width: calc(100% - 60px);
	padding-left: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.DogeCopy {
	height: 40px;
	width: 40px;
	margin-left: 10px;
}

.Link {
	padding: 20px 0px;
	text-decoration: none;
	color: $white;
}

.Twitter {
	font-size: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 750px){
	.Support {
		height: max-content;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		width: 100%;
	}
	.SupportInfo {
		grid-row: 1;
		padding-bottom: 20px;
		width: 100%;
	}
	.Patreon {
		width: calc(100% - 40px); 
	}
	.DogeContainer {
		margin-top: 10px;
	}
}

