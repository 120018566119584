@import './../../default.scss';

.About {
	padding: 20px;
	padding-bottom: 40px;
	text-align: center;
	font-size: $normalFont;
	line-height: $normalLineheight;
	width: 600px;
	max-width: calc(100% - 20px);
	margin: 0 auto;
	background-color: $black;
	border-radius: 5px;
	box-shadow: 0px 4px 20px $dropShadow;
	display: flex; 
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.Container {
	max-width: calc(100% - 20px);
	background-color: $black;
	border-radius: 5px;
	box-shadow: 0px 4px 20px $dropShadow;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}

.InnerContainer {
	border-radius: 5px;
	width: 100%;
	height: 100%;
	border: 2px solid $transparentWhite;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 40px;
	position: relative;
}

.ChildContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Icon {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 40px;
	height: 40px;
	color: $white;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}