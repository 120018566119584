@import './default.scss';
body, html, html *, #root {
	margin: 0;
	padding:0;
	box-sizing: border-box;
}

html {
	background-color: $backgroundColor;
	height: 100vh;
	width: 100vw;
	max-height: 100%;
	overflow: hidden;
}

body {
	background-image: url('./images/background-texture2.png');
	background-repeat: repeat;
	background-size: 50px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow: hidden;
	// overflow-y: auto;
	// overflow-x: hidden;
	font-family: 'Didact Gothic', sans-serif;
}

.App{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
  }

  .PageWrapper {
	height: 100%;
	width: 100%;
	max-height: 100%;
	overflow: hidden;
	padding-bottom: 200px;
	display: grid;
	grid-template-rows: 200px calc(100% - 200px);
	// border: 4px solid red;
 }
 .PageWrapper main {
	height: calc(100vh - 200px);
	max-height: calc(100vh - 200px);
	overflow-y: auto;
	padding-bottom: 40px;
}

  .Header {
	  font-size: $headerFont;
	  color: $white;
  }

  .Paragraph {
	  max-width: calc(100% - 20px);
	  color: $white;
  }

  .FlexCenter {
	  display: flex;
	  align-items: center;
	  justify-content: center;
  }

  /* width */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: $grey;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: $black;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: $black;
  }


  @media only screen and (min-width: 500px) and (max-width: 750px){
	.PageWrapper {
		grid-template-rows: 200px calc(100% - 200px);
	}
	.PageWrapper main {
		height: calc(100vh - 200px);
		max-height: calc(100vh - 200px);
	}
}

@media only screen and (max-width: 500px){
	.PageWrapper {
		grid-template-rows: 150px calc(100% - 150px);
	}
	.PageWrapper main {
		height: calc(100vh - 150px);
		max-height: calc(100vh - 150px);
	}
}