$backgroundColor: #494949;

$bannerTeal: #0eaf99;
$bannerOrange:#df9c20;
$bannerPurple: rgb(154, 73, 170);
$bannerFushia: #cf44a1;
$bannerBlue: rgb(68, 137, 216);
$bannerGreen: rgb(86, 199, 92);
$bannerYellow: rgb(221, 202, 94);
$bannerRed: #d35353;



$errorColor: #884141;
$dropShadow: rgba(0,0,0,0.25);
$trueBlack: black;
$white: #e0e0e0;
$transparentWhite: #e0e0e075;
$black: #2b2b2b;
$grey: #626262;
$darkGrey: #3d3d3d;
$lightGrey: #868686;


// Font sizes

$headerFont: 20px;
$normalFont: 16px;

$normalLineheight: 26px;